import LínuEfnisgrein from "../../línuefnisgrein/LínuEfnisgrein";
import TakkiAðgerð1 from "../../takkar/takkiAðgerð1/TakkiAðgerð1";
import "../greiðsla.css";
import "../../../../almenncss/grunncss.css";
import "../../../../almenncss/villaoghleður.css";
import { useEffect, useState } from "react";
import Smeija from "../../smeija/Smeija";
import SkráGreiðslu from "../skrágreiðslu/SkráGreiðslu";
import { fáGreiðsluaðferð, fáMiðlaravillu } from "../../../../gjörníngar/ímsir";
import miðlarabón from "../../../../miðlarabón";
import { bætaNúlliEfÞarf } from "../../../../gjörníngar/fáTímaLesmál";
import VilluHleðurTakki2 from "../../villaoghleður/villuhleðurtakki/VilluHleðurTakki2"
import SínaGreiðslu from "../sínagreiðslu/SínaGreiðslu";


const GreiðslaíLeik = ({
  tegund,
  einkileiks,
  sóttGreiðslaVerð,
  tímasetníng
}) => {
  const [skráGreiðslu, skikkaSkráGreiðslu] = useState(false);
  const [valinGreiðsla, skikkaValdriGreiðslu] = useState(sóttGreiðslaVerð.current.greiðsla);


  const [svarTakkar, skikkaSvarTakkar] = useState(null);
  const [hleðurTakkar, skikkaHleðurTakkar] = useState(null);
  const [villaTakkar, skikkaVillaTakkar] = useState(null);

  const [verð, skikkaVerði] = useState(sóttGreiðslaVerð.current.verð);
  const [hleðurVerð, skikkaHleðurVerð] = useState(null);
  const [villaVerð, skikkaVillaVerð] = useState(null);


  //const verð = useRef(leikur.verð.sótt ? leikur.verð.verð : null);

  /*
  
  aðferð: 1
  greiðslunúmer: "5to4gno65kly"
  textiAðferð: "Reiðufé"
  tími: Tue Sep 10 2024 00:00:00 GMT+0000 (Greenwich Mean Time) {}
  tímiTexti: "2024-9-10"
  upphæð: 3000

  */

  useEffect(() => {

    async function sækjaVerðogGreiðslu() {
      skikkaHleðurVerð(sóttGreiðslaVerð.current.sóttgreiðsla ? "Sækir verð..." : "Sækir greiðslu...");
      skikkaVillaVerð(null);
      try {
        const fágreiðslu = await miðlarabón.get(`/timaskra/gutlari/greidsla/${einkileiks}?tegundleiks=${tegund}`);
        if (fágreiðslu.data === "Ekkert verð né greiðsla") {
          skikkaHleðurVerð(null);
          return;
        }
        const feingiðVerð = fágreiðslu.data.verð;
        const feinginGreiðsla = fágreiðslu.data.greiðsla;
        //setja rétt upp valda greiðslu
        if (feinginGreiðsla) {
          feinginGreiðsla.textiAðferð = fáGreiðsluaðferð(feinginGreiðsla.aðferð);
          if (feinginGreiðsla.tími) {
            feinginGreiðsla.tími = new Date(feinginGreiðsla.tími);
            const d = feinginGreiðsla.tími;
            feinginGreiðsla.tímiTexti = `${d.getFullYear()}-${bætaNúlliEfÞarf(d.getMonth() + 1)}-${bætaNúlliEfÞarf(d.getDate())} ${bætaNúlliEfÞarf(d.getHours())}:${bætaNúlliEfÞarf(d.getMinutes())}`
          } else {
            feinginGreiðsla.tímiTexti = "";
          }
        }
        const verðVallar = feingiðVerð ? feingiðVerð.split("\n") : null;
        sóttGreiðslaVerð.current = {greiðsla: feinginGreiðsla, verð: verðVallar, sóttgreiðsla: true, sóttverð: true};
        skikkaVerði(verðVallar);
        skikkaValdriGreiðslu(feinginGreiðsla);
      } catch (villaBónar) {
        skikkaVillaVerð(fáMiðlaravillu(villaBónar));
      } 
      skikkaHleðurVerð(null);
    }
    if (!sóttGreiðslaVerð.current.sóttverð || !sóttGreiðslaVerð.current.sóttgreiðsla) {
      sækjaVerðogGreiðslu();
    } 

  }, [])

  //sótt er greiðslu og verð
  //annað getur verð eða ekkert eða hvorugt

  //Þegar sótt er, er bæði sótt verð og greiðslu, þannig sótt er firir bæði í einu
  //Þá þarf ekki að sækja aftur

  //en ef breitt er greiðslu er hún breitt bara í breitíngunni

  //Þannig ef sótt, þá þegar breitt er leik er sótt bara verð nís vallar ef það á við



  async function eiðaGreiðslu() {
    skikkaHleðurTakkar("Eyðir greiðslu...");
    skikkaVillaTakkar(null);
    try {
      const eiðíng = await miðlarabón.put(`/timaskra/gutlari/eidagreidslu/${einkileiks}`, {einkigreiðslu: valinGreiðsla.einki, tegundleiks: tegund});
      sóttGreiðslaVerð.current = {greiðsla: null, verð: sóttGreiðslaVerð.current.verð, sóttgreiðsla: sóttGreiðslaVerð.current.sóttgreiðsla, sóttverð: sóttGreiðslaVerð.current.sóttverð, breitt: true};
      skikkaSvarTakkar(eiðíng.data);
      skikkaValdriGreiðslu(null);
    } catch (villaBónar) {
      skikkaVillaTakkar(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurTakkar(null);
  }


  async function breitaGreiðslu(greiðsla) {
    skikkaHleðurTakkar("Breytir greiðslu...");
    skikkaVillaTakkar(null);
    const einkigreiðslu = valinGreiðsla ? valinGreiðsla.einki : null;
    try {
      const breitíng = await miðlarabón.put(`/timaskra/gutlari/breitagreidslu/${einkileiks}`, {einkigreiðslu, greiðsla, tegundleiks: tegund});
      if (breitíng.data.feinginnEinki) {
        //þegar búið er til níja greiðslu þá er bætt við feinginn einka úr gagnagrunni við níju greiðsluna
        greiðsla.einki = breitíng.data.feinginnEinki;
      }
      sóttGreiðslaVerð.current = {greiðsla: greiðsla, verð: sóttGreiðslaVerð.current.verð, sóttgreiðsla: sóttGreiðslaVerð.current.sóttgreiðsla, sóttverð: sóttGreiðslaVerð.current.sóttverð, breitt: true};
      skikkaSvarTakkar(breitíng.data.skilaboð);
      skikkaValdriGreiðslu(greiðsla);
    } catch (villaBónar) {
      skikkaVillaTakkar(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurTakkar(null);
  }
  


  return (
    <>
      {valinGreiðsla ? (
        <>
          <p className="smeija-aðaltakki">Greiðsla:</p>
          <SínaGreiðslu greiðsla={valinGreiðsla}/>
            <div className="umgjörð-lárétt tveir-takkar skrágreiðslu-tími">
              {hleðurTakkar && (
                <div className="smeija-takki villaoghleður-hleður">
                  {hleðurTakkar}
                </div>
              )}
              {villaTakkar && (
                <div className="smeija-takki villaoghleður-villa">
                  {villaTakkar}
                </div>
              )}
              {(!hleðurTakkar && !svarTakkar) && (
                <>
                  <div className="smeija-takki">
                    <TakkiAðgerð1 texti="Breyta greiðslu" aðgerð={() => skikkaSkráGreiðslu(true)} />
                  </div>
                  <div className="smeija-takki">
                    <TakkiAðgerð1 texti="Eyða greiðslu" aðgerð={eiðaGreiðslu} />
                  </div>
                </>
              )}
            </div>
        </>
      ) : (
        verð && (
          <>
            <p className="smeija-takki skrágreiðslu-verð">Verð vallar:</p>
            <LínuEfnisgrein listi={verð}/>
            <div className="smeija-takki skrágreiðslu-tími">
              <VilluHleðurTakki2
                textiAðgerðar="Skrá greiðslu"
                aðgerð={() => skikkaSkráGreiðslu(true)}
                hleður={hleðurTakkar}
                villa={villaTakkar}
              />
            </div>
          </>
        )
      )}
      {svarTakkar && (
        <p className="smeija-takki villaoghleður-tókst">
          {svarTakkar}
        </p>
      )}
      {hleðurVerð && <p className="smeija-takki villaoghleður-hleður">{hleðurVerð}</p>}
      {villaVerð && <p className="smeija-takki villaoghleður-villa">{villaVerð}</p>}
      {skráGreiðslu && (
        <Smeija lokaSmeiju={skikkaSkráGreiðslu}>
          <SkráGreiðslu 
            verð={verð} 
            breitaGreiðslu={breitaGreiðslu} 
            valinGreiðsla={valinGreiðsla}
            lokaSmeiju={skikkaSkráGreiðslu}
            tímasetníng={tímasetníng}
          />
        </Smeija>
      )}
    </>


  )
}


export default GreiðslaíLeik;