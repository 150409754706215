import "./árnaðarbandsmeija.css";
import "../../../../../almenncss/grunncss.css";
import "../../../../../hlutir/almennirhlutar/listi/leppurtímaskrá/smeijur/leikurtímaskrá/leikurtímaskrá.css";
import { fáDagLesmál, fáFráTilLesmál } from "../../../../../gjörníngar/fáTímaLesmál";
import { useEffect, useMemo, useRef, useState } from "react";
import VilluHleðurTakki from "../../../../../hlutir/almennirhlutar/villaoghleður/villuhleðurtakki/VilluHleðurTakki";
import { fáMiðlaravillu } from "../../../../../gjörníngar/ímsir";
import miðlarabón from "../../../../../miðlarabón";
import { useParams } from "react-router-dom";
import SpjallÁrnaðarmaður from "../../../../../hlutir/almennirhlutar/spjallárnaðarmaður/SpjallÁrnaðarmaður";
import GreiðslaíLeik from "../../../../../hlutir/almennirhlutar/greiðsla/greiðslaíleik/GreiðslaíLeik";


//Texti aðgerðartakka miðað við fráteknistöðu vallar
export function fáTextaTakka(upptekni) {
  if (upptekni === "Uppteknarbón") return "Afturkalla bón";
  if (upptekni === "Upptekinn") return "Afturkalla upptekni";
  if (upptekni === "Skipaður") return "Senda uppteknarbón";
  return "";
}

//gildi tegundar leiks í gagnasafni
export function fáTegundLeiks(tegund) {
  if (tegund === "gutlaraleikur") return 1;
  if (tegund === "kappleikur") return 2;
  return null;
}


const ÁrnaðarbandSmeija = ({leikur, tegundleiks, skikkaLeik}) => {


  const [svar, skikkaSvari] = useState(null);
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);


  const breitaUpptekni = useRef(leikur.leikur.upptekni);
  const breittNíSkilaboð = useRef(leikur.leikur.ní_skilaboð);
  const tegund = useRef(fáTegundLeiks(tegundleiks));
  const sóttGreiðslaVerð = useRef({greiðsla: leikur.greiðsla.greiðsla, verð: leikur.greiðsla.verð, sóttgreiðsla: leikur.greiðsla.sóttgreiðsla, sóttverð: leikur.greiðsla.sóttverð});
  
  const {einkileiks} = useParams();

  async function eigaViðUpptekni(textiAðgerðar) {
    let slóð;
    let hleðurSkilaboð;
    let breittUpptekni;
    if (textiAðgerðar === "Afturkalla bón") {
      slóð = "afturkallabon";
      hleðurSkilaboð = "Afturkallar...";
      breittUpptekni = "Skipaður";
    } else if (textiAðgerðar === "Afturkalla upptekni") {
      slóð = "afturkallaupptekni";
      hleðurSkilaboð = "Afturkallar...";
      breittUpptekni = "Skipaður";
    } else if (textiAðgerðar === "Senda uppteknarbón") {
      slóð = "sendauppteknarbon";
      hleðurSkilaboð = "Sendir...";
      breittUpptekni = "Uppteknarbón";
    } else {
      return;
    }

    skikkaHleður(hleðurSkilaboð);
    skikkaVillu(null);

    try {
      const svarMiðlara = await miðlarabón.put(`timaskra/gutlari/${slóð}/${leikur.leikur.tímaskrá}`, {tegundleiks: tegund.current, einkileiks});
      breitaUpptekni.current = breittUpptekni;
      skikkaSvari(svarMiðlara.data);
    } catch (villaBónar) {
      skikkaVillu(fáMiðlaravillu(villaBónar))
    }
    skikkaHleður(null);

  }

  const tímasetníng = useMemo(() => {
    const tímiFrá = new Date(leikur.leikur.tími_frá); 
    const tímiTil = new Date(leikur.leikur.tími_til);
    return {dagur: fáDagLesmál(tímiFrá), tími: fáFráTilLesmál(tímiFrá, tímiTil)}
  }, [])


  const textiTakka = fáTextaTakka(leikur.leikur.upptekni);

  useEffect(() => {

    //ef einkverju var breitt þá vista breitínga í leikur
    return () => {
      const upptekniBreitt = breitaUpptekni.current !== leikur.leikur.upptekni;
      const skilaboðBreitt = breittNíSkilaboð.current !== leikur.leikur.ní_skilaboð;
      const verðBreitt = sóttGreiðslaVerð.current.sóttverð !== leikur.greiðsla.sóttverð;
      const greiðslaBreitt = sóttGreiðslaVerð.current.breitt || sóttGreiðslaVerð.current.sóttgreiðsla !== leikur.greiðsla.sóttgreiðsla;
      if (upptekniBreitt || skilaboðBreitt || greiðslaBreitt || verðBreitt) {
        skikkaLeik(firriLeikur => {
          const breitturLeikur = {...firriLeikur};
          breitturLeikur.leikur.upptekni = breitaUpptekni.current;
          breitturLeikur.leikur.ní_skilaboð = breittNíSkilaboð.current;
          breitturLeikur.greiðsla.greiðsla = sóttGreiðslaVerð.current.greiðsla;
          breitturLeikur.greiðsla.verð = sóttGreiðslaVerð.current.verð;
          breitturLeikur.greiðsla.sóttgreiðsla = sóttGreiðslaVerð.current.sóttgreiðsla;
          breitturLeikur.greiðsla.sóttverð = sóttGreiðslaVerð.current.sóttverð;
          
          return breitturLeikur;
        })
      }
    }

  }, [])


  /*
  
    greiðsluaðferð:null
    greiðslunúmer:null
    tímigreiðslu:null
    upphæð:null

    Kvernig ætti ég að hafa greiðsluna hér
    og kvernig ætti ég að hátta greiðslu þegar breitt er um tíma eða völl

    hér ætti að vera: {
      ef ekki skráð verð: {
        ef völlur með verði:{
          Þá stendur verð
          Og takki til að bæta við verði
        }
      } annars: {
        Stendur skráð greiðsla
        svo takkar firir neðan: eiða og breita
      }
    
    }

    í breitíngu ætti að vera: {
      ef ekki skráð verð: {
        ef völlur með verði: {
          Þá stendur verð
          Og takki til að bæta við verði
        }
      } annars: {
        
      }
    
    }


    Setja inn þannig greiðsla virkar í tímaskráníngarsmeiju


  */



  return (
    <section className="árnband-aðal umgjörð-lóðrétt-ómiðjað">
      <h3 className="leikurtímaskrá-firirsögn">{leikur.leikur.nafnvallar} {leikur.leikur.aukanafn} {leikur.leikur.stærð_undirvallar}</h3>
      {leikur.leikur.tími_frá && (
        <>
          <p className="leikurtímaskrá-tími">{tímasetníng.dagur}</p>
          <p className="leikurtímaskrá-tími">{tímasetníng.tími}</p>
        </>
      )}
      <GreiðslaíLeik
        tegund={tegund.current}
        einkileiks={einkileiks}
        sóttGreiðslaVerð={sóttGreiðslaVerð}
        tímasetníng={tímasetníng}
      />
      {leikur.leikur.tímaskrá && leikur.leikur.árnaðarmaður && !leikur.leikur.einkavöllur && (
        <>
          <p className="leikurtímaskrá-gögn">Upptekni leiks: {breitaUpptekni.current}</p>
          <div className="leikurtímaskrá-takkar umgjörð-lóðrétt-ómiðjað">
            <VilluHleðurTakki
              svarMiðlara={svar}
              hleður={hleður}
              villa={villa}
              textiAðgerðar={textiTakka}
              aðgerð={() => eigaViðUpptekni(textiTakka)}
            />
          </div>
        </>
      )}
      <h5 className="leikurtímaskrá-gögn">Spjall við árnaðarmenn</h5>
      <p className="smeija-lítilltakki">Til að fá ný skilaboð þarf að fara úr spjalli og aftur inn í það</p>
      <SpjallÁrnaðarmaður
        einkileiks={einkileiks}
        breittNíSkilaboð={breittNíSkilaboð}
        tegundLeiks={tegund.current}
        sendaSlóð={`timaskra/gutlari/sendaskilabod/${einkileiks}`}
        sækjaSlóð={`timaskra/gutlari/saekjaskilabod/${einkileiks}?tegund=${tegund.current}&`}
      />
    </section>
  )
}


export default ÁrnaðarbandSmeija;