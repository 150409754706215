import LínuEfnisgrein from "../../línuefnisgrein/LínuEfnisgrein";
import TakkiAðgerð1 from "../../takkar/takkiAðgerð1/TakkiAðgerð1";
import "../greiðsla.css";
import "../../../../almenncss/grunncss.css";
import "../../../../almenncss/villaoghleður.css";
import { useState } from "react";
import Smeija from "../../smeija/Smeija";
import SkráGreiðslu from "../skrágreiðslu/SkráGreiðslu";
import { fáMiðlaravillu } from "../../../../gjörníngar/ímsir";
import miðlarabón from "../../../../miðlarabón";
import { bætaNúlliEfÞarf } from "../../../../gjörníngar/fáTímaLesmál";
import VilluHleðurTakki2 from "../../villaoghleður/villuhleðurtakki/VilluHleðurTakki2"
import SínaGreiðslu from "../sínagreiðslu/SínaGreiðslu";

function reiknaGreiðslu(greiðsla) {
  if (!greiðsla) {
    return null;
  }
  greiðsla.textiAðferð = greiðsla.aðferð;
  if (greiðsla.tími) {
    greiðsla.tími = new Date(greiðsla.tími);
    const d = greiðsla.tími;
    greiðsla.tímiTexti = `${d.getFullYear()}-${bætaNúlliEfÞarf(d.getMonth() + 1)}-${bætaNúlliEfÞarf(d.getDate())} ${bætaNúlliEfÞarf(d.getHours())}:${bætaNúlliEfÞarf(d.getMinutes())}`
  } else {
    greiðsla.tímiTexti = "";
  }
  return greiðsla;
}

const GreiðslaFirirætlun = ({
  firirætlun,
  einkiFirirætlunar
}) => {

  const [skráGreiðslu, skikkaSkráGreiðslu] = useState(false);
  const [valinGreiðsla, skikkaValdriGreiðslu] = useState(reiknaGreiðslu(firirætlun.greiðsla));

  const [svarTakkar, skikkaSvarTakkar] = useState(null);
  const [hleðurTakkar, skikkaHleðurTakkar] = useState(null);
  const [villaTakkar, skikkaVillaTakkar] = useState(null);

  async function eiðaGreiðslu() {
    skikkaHleðurTakkar("Eyðir greiðslu...");
    skikkaVillaTakkar(null);
    try {
      const eiðíng = await miðlarabón.put(`/firiraetlanir/eidagreidslu/${einkiFirirætlunar}`, {einkigreiðslu: valinGreiðsla.einki});
      skikkaSvarTakkar(eiðíng.data);
      skikkaValdriGreiðslu(null);
    } catch (villaBónar) {
      skikkaVillaTakkar(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurTakkar(null);
  }


  async function breitaGreiðslu(greiðsla) {
    skikkaHleðurTakkar("Breytir greiðslu...");
    skikkaVillaTakkar(null);
    const einkigreiðslu = valinGreiðsla ? valinGreiðsla.einki : null;
    try {
      const breitíng = await miðlarabón.put(`/firiraetlanir/breitagreidslu/${einkiFirirætlunar}`, {einkigreiðslu, greiðsla});
      if (breitíng.data.feinginnEinki) {
        //þegar búið er til níja greiðslu þá er bætt við feinginn einka úr gagnagrunni við níju greiðsluna
        greiðsla.einki = breitíng.data.feinginnEinki;
      }
      skikkaSvarTakkar(breitíng.data.skilaboð);
      skikkaValdriGreiðslu(greiðsla);
    } catch (villaBónar) {
      skikkaVillaTakkar(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurTakkar(null);
  }
  

  return (
    <>
      {valinGreiðsla ? (
        <>
          <p className="smeija-aðaltakki">Greiðsla:</p>
          <SínaGreiðslu greiðsla={valinGreiðsla}/>
            <div className="umgjörð-lárétt tveir-takkar skrágreiðslu-tími">
              {hleðurTakkar && (
                <div className="smeija-takki villaoghleður-hleður">
                  {hleðurTakkar}
                </div>
              )}
              {villaTakkar && (
                <div className="smeija-takki villaoghleður-villa">
                  {villaTakkar}
                </div>
              )}
              {(!hleðurTakkar && !svarTakkar) && (
                <>
                  <div className="smeija-takki">
                    <TakkiAðgerð1 texti="Breyta greiðslu" aðgerð={() => skikkaSkráGreiðslu(true)} />
                  </div>
                  <div className="smeija-takki">
                    <TakkiAðgerð1 texti="Eyða greiðslu" aðgerð={eiðaGreiðslu} />
                  </div>
                </>
              )}
            </div>
        </>
      ) : (
        firirætlun.verðvallar && (
          <>
            <p className="smeija-takki skrágreiðslu-verð">Verð vallar:</p>
            <LínuEfnisgrein listi={firirætlun.verðvallar}/>
            <div className="smeija-takki skrágreiðslu-tími">
              <VilluHleðurTakki2
                textiAðgerðar="Skrá greiðslu"
                aðgerð={() => skikkaSkráGreiðslu(true)}
                hleður={hleðurTakkar}
                villa={villaTakkar}
              />
            </div>
          </>
        )
      )}
      {svarTakkar && (
        <p className="smeija-takki villaoghleður-tókst">
          {svarTakkar}
        </p>
      )}
      {skráGreiðslu && (
        <Smeija lokaSmeiju={skikkaSkráGreiðslu}>
          <SkráGreiðslu 
            verð={firirætlun.verðvallar} 
            breitaGreiðslu={breitaGreiðslu} 
            valinGreiðsla={valinGreiðsla}
            lokaSmeiju={skikkaSkráGreiðslu}
            firirætlun={firirætlun}
          />
        </Smeija>
      )}
    </>


  )
}


export default GreiðslaFirirætlun;