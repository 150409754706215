import "../../../almenncss/grunncss.css";


const LínuEfnisgrein = ({listi}) => {

  return (
    <p className="texti">
      {listi.map((texti, staða) => {
        if (staða === 0) {
          return <span key={staða}>{texti}</span>
        } else {
          return <span key={staða}><br/>{texti}</span>
        }
      })}
    </p>

  )
}

export default LínuEfnisgrein;