import TakkiAðgerð1 from "../../../hlutir/almennirhlutar/takkar/takkiAðgerð1/TakkiAðgerð1";
import TakkiAðgerð2 from "../../../hlutir/almennirhlutar/takkar/takkiAðgerð2/TakkiAðgerð2";
import ÚtfillíngarMöguleikar from "../../../hlutir/almennirhlutar/útfillíngar/útfillíngarmöguleikar/ÚtfillíngarMöguleikar";
import ÚtfillíngarTala from "../../../hlutir/almennirhlutar/útfillíngar/útfillíngartala/ÚtfillíngarTala";
import ÚtfillíngarHak from "../../../hlutir/almennirhlutar/útfillíngar/útfillíngarhak/ÚtfillíngarHak"
import Smeija from "../../../hlutir/almennirhlutar/smeija/Smeija";
import "../../../hlutir/almennirhlutar/veljatíma/veljatímasetníngar/veljatímasetníngar.css";
import fáTímasetníngarLesmál from "../../../gjörníngar/fáTímasetnínguLesmál";
import "./opnirleikirval.css";
import "../../../almenncss/grunncss.css";
import { lazy, Suspense, useState } from "react";
import VeljaVöll from "../../../hlutir/almennirhlutar/veljavöll/VeljaVöll";
import VeljaEinnTíma from "../../../hlutir/almennirhlutar/veljaeinntíma/VeljaEinnTíma";
import { íþróttir } from "../../../almenngögn";
import SmeijaHleður from "../../../hlutir/almennirhlutar/smeijahleður/SmeijaHleður";
const VeljaÞorp = lazy(() => import("../../../hlutir/almennirhlutar/veljaþorp/VeljaÞorp"));


const OpnirLeikirVal = ({
  sækjaOpnaLeiki,
  leitarhleður
}) => {
  const [veljaAldur, skikkaVeljaAldur] = useState(false);
  const [neðriMörkAldurs, skikkaNeðriMörkumAldurs] = useState(19);
  const [efriMörkAldurs, skikkaEfriMörkumAldurs] = useState(69);

  const [kin, skikkaKini] = useState(null);
  const [leikháttur, skikkaLeikhætti] = useState(null);
  const [tegundOpinn, skikkaTegundOpinn] = useState(null);

  const [veljaNeðriMörkTíma, skikkaVeljaNeðriMörkTíma] = useState(false);
  const [neðriMörkTíma, skikkaNeðriMörkumTíma] = useState(null);
  const [veljaEfriMörkTíma, skikkaVeljaEfriMörkTíma] = useState(false);
  const [efriMörkTíma, skikkaEfriMörkumTíma] = useState(null);
  const [ánTíma, skikkaÁnTíma] = useState(false);

  const [veljaÞorp, skikkaVeljaÞorp] = useState(false);
  const [veljaVöll, skikkaVeljaVöll] = useState(false);
  const [þorp, skikkaÞorpi] = useState(null);
  const [völlur, skikkaVelli] = useState(null);

  const [spurnOpinn, skikkaSpurnOpinn] = useState(false);

  return (
    <>
      <div className="opnirleikir-valkostir">
        <h3 className="opnirleikir-leita-firirsögn">Leit</h3>
        <div className="opnirleikir-valkostir-listi">
          <div className="opnirleikir-valkostur">
            <TakkiAðgerð1 
              texti={veljaAldur ? "Sleppa aldri" : "Velja aldursbil"} 
              aðgerð={() => skikkaVeljaAldur(firraGildi => !firraGildi)}
            />
            {veljaAldur && (
              <>
                <div className="opnirleikir-útfillíng">
                  <ÚtfillíngarTala 
                    texti="Efri mörk aldurs" 
                    gildi={efriMörkAldurs} 
                    breitaGildi={skikkaEfriMörkumAldurs}
                  />
                </div>
                <div className="opnirleikir-útfillíng">
                  <ÚtfillíngarTala 
                    texti="Neðri mörk aldurs" 
                    gildi={neðriMörkAldurs} 
                    breitaGildi={skikkaNeðriMörkumAldurs}
                  />
                </div>
              </>
            )}
          </div>
          <div className="opnirleikir-valkostur">
            <div className="opnirleikir-útfillíng">
              <ÚtfillíngarMöguleikar 
                texti="Kyn" 
                gildi={kin} 
                breitaGildi={skikkaKini} 
                möguleikar={["Bæði", "Sveinar", "Meyjur"]}
              />
            </div>
          </div>
          {!ánTíma && (
            <>
              <div className="opnirleikir-valkostur">
                <p className="opnirleikir-firirsögn-tímar">Leita af leikjum eftir þennan tíma:</p>
                {neðriMörkTíma && (
                  <>
                    <p className="opnirleikir-tímar">{fáTímasetníngarLesmál(neðriMörkTíma)}</p>
                  </>
                )}
                <TakkiAðgerð1 
                  texti={neðriMörkTíma ? "Sleppa tíma" : "Velja tíma"} 
                  aðgerð={() => {
                    if (neðriMörkTíma) {
                      skikkaNeðriMörkumTíma(null);
                    } else {
                      skikkaVeljaNeðriMörkTíma(true);
                    }
                  }}
                />
                {neðriMörkTíma && (
                  <TakkiAðgerð1 
                    texti="Breyta tíma" 
                    aðgerð={() => skikkaVeljaNeðriMörkTíma(true)}
                  />
                )}
              </div>
              <div className="opnirleikir-valkostur">
                <p className="opnirleikir-firirsögn-tímar">Leita af leikjum fyrir þennan tíma:</p>
                {efriMörkTíma && (
                  <>
                    <p className="opnirleikir-tímar">{fáTímasetníngarLesmál(efriMörkTíma)}</p>
                  </>
                )}
                <TakkiAðgerð1 
                  texti={efriMörkTíma ? "Sleppa tíma" : "Velja tíma"} 
                  aðgerð={() => {
                    if (efriMörkTíma) {
                      skikkaEfriMörkumTíma(null);
                    } else {
                      skikkaVeljaEfriMörkTíma(true);
                    }
                  }}
                />
                {efriMörkTíma && (
                  <TakkiAðgerð1 
                    texti="Breyta tíma" 
                    aðgerð={() => skikkaVeljaEfriMörkTíma(true)}
                  />
                )}
              </div>
            </>
          )}
          <div className="opnirleikir-valkostur">
            <div className="opnirleikir-útfillíng">
              <ÚtfillíngarHak
                texti="Aðeins leikir án tíma"
                gildi={ánTíma}
                breitaGildi={skikkaÁnTíma}
              />
            </div>
          </div>
          <div className="opnirleikir-valkostur">
            <div className="opnirleikir-útfillíng">
              <ÚtfillíngarMöguleikar 
                texti="Hversu opinn" 
                gildi={tegundOpinn} 
                breitaGildi={skikkaTegundOpinn} 
                möguleikar={["Bæði","Opinn", "Bláopinn"]}
                birtaSmeiju={skikkaSpurnOpinn}
              />
            </div>
          </div>
          <div className="opnirleikir-valkostur">
            <div className="opnirleikir-útfillíng">
              <ÚtfillíngarMöguleikar 
                texti="Leikhættir" 
                gildi={leikháttur} 
                breitaGildi={skikkaLeikhætti} 
                möguleikar={["Allir", ...íþróttir]}
              />
            </div>
          </div>
          <div className="opnirleikir-valkostur opnirleikir-valkostur-leingri">
            {!(þorp || völlur) && (
              <>
                <TakkiAðgerð1 texti="Velja nágrenni" aðgerð={() => skikkaVeljaÞorp(true)}/>
                <p className="opnirleikir-firirsögn-tímar">eða</p>
                <TakkiAðgerð1 texti="Velja völl" aðgerð={() => skikkaVeljaVöll(true)}/>
              </>
            )}
            {þorp && (
              <>
                <p className="opnirleikir-firirsögn-tímar">Nágrenni:</p>
                <p className="opnirleikir-tímar">{þorp.nafn}</p>
                <TakkiAðgerð1 texti="Velja annað" aðgerð={() => skikkaVeljaÞorp(true)}/>
                <TakkiAðgerð1 texti="Afvelja" aðgerð={() => skikkaÞorpi(null)}/>
                <TakkiAðgerð1 texti="Velja völl" aðgerð={() => {
                  skikkaVeljaVöll(true);
                  skikkaÞorpi(null);
                }}/>
              </>
            )}
            {völlur && (
              <>
                <p className="opnirleikir-firirsögn-tímar">Völlur:</p>
                <p className="opnirleikir-tímar">{völlur.nafn}</p>
                <TakkiAðgerð1 texti="Velja annan" aðgerð={() => skikkaVeljaVöll(true)}/>
                <TakkiAðgerð1 texti="Afvelja" aðgerð={() => skikkaVelli(null)}/>
                <TakkiAðgerð1 texti="Velja nágrenni" aðgerð={() => {
                  skikkaVeljaÞorp(true);
                  skikkaVelli(null);
                }}/>
              </>
            )}
          </div>
          
        </div>
        {!leitarhleður && (
          <div className="opnirleikir-leita">
            <TakkiAðgerð2 texti="Leita" aðgerð={() => {
              sækjaOpnaLeiki(
                veljaAldur, 
                neðriMörkAldurs, 
                efriMörkAldurs, 
                kin, 
                tegundOpinn, 
                leikháttur, 
                efriMörkTíma, 
                neðriMörkTíma,
                þorp,
                völlur,
                ánTíma
              )
            }}
            />
          </div>
        )}
      </div>
      {(veljaEfriMörkTíma || veljaNeðriMörkTíma) && (
        <Smeija 
          lokaSmeiju={(gildi) => {
            if (!gildi) {
              skikkaVeljaEfriMörkTíma(false);
              skikkaVeljaNeðriMörkTíma(false);
            }
          }}
        > 
          <VeljaEinnTíma
            skikkaTíma={veljaEfriMörkTíma ? skikkaEfriMörkumTíma : skikkaNeðriMörkumTíma}
            lokaSmeiju={(gildi) => {
              if (!gildi) {
                skikkaVeljaEfriMörkTíma(false);
                skikkaVeljaNeðriMörkTíma(false);
              }
            }}
          />
        </Smeija>
      )}
      {veljaÞorp && (
        <Smeija lokaSmeiju={skikkaVeljaÞorp}>
          <Suspense fallback={<SmeijaHleður texti="nágrenni"/>}>
            <VeljaÞorp skikkaÞorpi={skikkaÞorpi} lokaSmeiju={skikkaVeljaÞorp}/>
          </Suspense>
        </Smeija>
      )}
      {veljaVöll && (
        <Smeija lokaSmeiju={skikkaVeljaVöll}>
          <VeljaVöll skikkaVelli={skikkaVelli} lokaSmeiju={skikkaVeljaVöll}/>
        </Smeija>
      )}
      {spurnOpinn && (
        <Smeija lokaSmeiju={skikkaSpurnOpinn}>
          <p className="smeija-aðaltakki spurn-rammi">Opinn leikur: Leikur þar sem hver sem er getur sent bón um að fá að memma leik (ef leikur er takmarkaður við kyn eða aldur geta aðeins þeir sem passa við þá takmörkun sent memmbón).</p>
          <p className="smeija-aðaltakki spurn-rammi">Bláopinn leikur: Leikur þar sem hver sem er getur memmað leikinn beint (ef leikur er takmarkaður við kyn eða aldur geta aðeins þeir sem passa við þá takmörkun memmað beint).</p>
        </Smeija>
      )}
    </>
  )
}


export default OpnirLeikirVal;