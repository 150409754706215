
import { bætaNúlliEfÞarf, mánuðir, vikudagar } from "./fáTímaLesmál";

const fáTímasetníngarLesmál = (tími) => {
  let notaTíma = tími;
  if (Number.isInteger(tími) || typeof tími === "string") {  
    notaTíma = new Date(tími);
  }

  const tímiUppsettur = `${vikudagar[notaTíma.getDay()]} ${notaTíma.getDate()}.${mánuðir[notaTíma.getMonth()]} ${notaTíma.getFullYear()} klukkan ${notaTíma.getHours()}:${bætaNúlliEfÞarf(notaTíma.getMinutes())}`;
  

  return tímiUppsettur;
}

export default fáTímasetníngarLesmál;