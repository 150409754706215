import React, { useState } from "react"

//import "./leppurtímaskrá.css"
import "../leppuralmennleikur/leppuralmennleikur.css";
//import "../../../../almenncss/villaoghleður.css";
import { fáFráTilLesmál, fáDagLesmál } from "../../../../gjörníngar/fáTímaLesmál";
import { fáUpptekni } from "../leppurtímaskrá/LeppurTímaskrá.js";
import VilluHleðurTakki from "../../villaoghleður/villuhleðurtakki/VilluHleðurTakki.js";



//tímaskrá án leiks, sem fyrirætlun notar
const LeppurFirirtími = ({ valinTímaskrá, textiAðgerðar, aðgerð }) => {
  const [svarMiðlara, skikkaSvariMiðlara] = useState(null);
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);

  const tímiFrá = new Date(valinTímaskrá.tími_frá);
  const tímiTil = new Date(valinTímaskrá.tími_til);

  const upptekni = fáUpptekni(valinTímaskrá.upptekni);

  return (
    <>
      <div className="leppurleikur-leikur">
        <p className="leppurleikur-dagsetníng-leiks">{fáDagLesmál(tímiFrá)}</p>
        <p className="leppurleikur-tími-leiks">{fáFráTilLesmál(tímiFrá, tímiTil)}</p>
        {upptekni && <p className="leppurleikur-tími-leiks">{upptekni}</p>}
        {textiAðgerðar && (
          <div className="leppurleikur-takkakassi">
            <VilluHleðurTakki
              svarMiðlara={svarMiðlara}
              villa={villa}
              hleður={hleður}
              aðgerð={() => aðgerð(valinTímaskrá, skikkaSvariMiðlara, skikkaHleður, skikkaVillu)}
              textiAðgerðar={textiAðgerðar}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default LeppurFirirtími;