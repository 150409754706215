import React, { useEffect, useState } from "react"
import "./útfillíngartexti.css";
import "../../../../almenncss/grunncss.css";

const ÚtfillingarTexti = ({texti, gildi, breitaGildi, tegund, staðfestaTakki, birtaSmeiju}) => {
  const [orð, skikkaOrði] = useState(gildi);

  //finna út afhverju ég gerði þetta aftur, ætti þetta samt ekki að seija sig nokkuð sjálft
  useEffect(() => {
    skikkaOrði(gildi);
  }, [gildi])
  
  return (
    <label className="útftexti-aðal">
      {texti && <p className="útftexti-lesmál">{texti} {birtaSmeiju && (<span onClick={(v) => {
        v.preventDefault();
        birtaSmeiju();
      }} className="spurn smellanlegur">?</span>)}</p>} 
      <input
        className={`útftexti-inntak ${texti ? "" : "útftexti-inntak-stærra"}`}
        placeholder=""
        spellCheck="false"
        type={tegund}
        name={texti}
        value={orð}
        onChange={(e) => {
          breitaGildi(e.target.value);
          skikkaOrði(e.target.value);
        }} 
        onKeyDown={(e) => {
          if (e.key === "Enter" && staðfestaTakki) {
            if (e) {
              //á ég kannski bara að sleppa þessu?
              e.preventDefault();
            }
            staðfestaTakki();
          }
        }}
      />
    </label>
  )
}

export default ÚtfillingarTexti;