import { useEffect, useRef } from "react";
import "./völlur.css";
import "../../almenncss/villaoghleður.css";
import "../../almenncss/grunncss.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import miðlarabón from  "../../miðlarabón.js"
import TakkiAðgerð2 from "../../hlutir/almennirhlutar/takkar/takkiAðgerð2/TakkiAðgerð2.js"
import VeljaTíma from "../../hlutir/almennirhlutar/veljatíma/VeljaTíma";
import TakkiAðgerð1 from "../../hlutir/almennirhlutar/takkar/takkiAðgerð1/TakkiAðgerð1";
import { fáMiðlaravillu } from "../../gjörníngar/ímsir.js";
import Smeija from "../../hlutir/almennirhlutar/smeija/Smeija.js";
import Listi from "../../hlutir/almennirhlutar/listi/Listi.js";
import slóðminda from "../../slóðminda.js";
import SpurnStærðValla from "../../hlutir/almennirhlutar/spurnsmeijur/SpurnStærðValla.js";
import LínuEfnisgrein from "../../hlutir/almennirhlutar/línuefnisgrein/LínuEfnisgrein.js";

function fáLeingdOgBreiddVallar(leingd, breidd) {
  if (!leingd || !breidd) {
    return null;
  }
  //mesta breidd 24rem
  //mesta leingd 28rem
  let margföldunStærða = 1;
  //þannig ef leingd/breidd >= 28/24 þá ræður leingd margföldunarstærð
  //þá er leingd hlutfallslega leingri
  //ef leingd/breidd < 28/24 þá ræður breidd margföldunarstærð
  //þá er breidd hlutfallslega leingri
  //Þetta er svo leingri hliðin verði sem stæst og hin stækkar bara í hlutfalli við það
  const mestaLeingd = 28;
  const mestaBreidd = 22;
  //ef völlur er alltof stór miðað við
  if (leingd > mestaLeingd || breidd > mestaBreidd) {
    const leingdRæðurMargföldunarstærð = leingd/breidd >= mestaLeingd/mestaBreidd;
    const ráðandiStærð = leingdRæðurMargföldunarstærð ? leingd : breidd;
    const ráðandiMest = leingdRæðurMargföldunarstærð ? mestaLeingd : mestaBreidd;
    margföldunStærða = ráðandiMest/ráðandiStærð;
  }

  //en ef völlur er minni þarf að stækka hann, svipuð rök og hér firir ofan
  const minnstaBreidd = 6;
  const minnstaLeingd = 6;
  if (leingd < minnstaLeingd || breidd < minnstaBreidd) {
    const breiddRæðurMargföldunarstærð = breidd < leingd;
    const ráðandiStærð = breiddRæðurMargföldunarstærð ? breidd : leingd;
    const ráðandiMinnst = breiddRæðurMargföldunarstærð ? minnstaBreidd : minnstaLeingd;
    margföldunStærða = ráðandiMinnst/ráðandiStærð;
  }

  
  let leingdSínd = leingd * margföldunStærða;
  let breiddSínd = breidd * margföldunStærða;
  //ef leingd/breidd > 28/6 þá er ekki hægt að hafa völl í réttum hlutföllum
  //ef leingd/breidd < 6/22 þá er ekki hægt að hafa völl í réttum hlutföllum
  //sína þá bara minnstu eða mestu leingd/breidd
  if (breiddSínd < minnstaBreidd) {
    breiddSínd = minnstaBreidd;
  }
  if (leingdSínd < minnstaLeingd) {
    leingdSínd = minnstaLeingd
  }
  if (breiddSínd > mestaBreidd) {
    breiddSínd = mestaBreidd;
  }
  if (leingdSínd > mestaLeingd) {
    leingdSínd = mestaLeingd;
  }

  return {width: `${breiddSínd}em`, height: `${leingdSínd}em`}
} 

const Völlur = ({einkivallar, lokasmeiju, árnaðarmaður}) => {
  const [völlur, skikkaVelli] = useState(null);
  const [sækirVallargögn, skikkaSækirVallargögn] = useState("Sækir völl...");
  const [miðlaravilla, skikkaMiðlaravillu] = useState(null);

  const [sjáAðalvöll, skikkaSjáAðalvöll] = useState(false);

  const [sjáDagskráVallar, skikkaSjáDagskráVallar] = useState(false);

  const [spurn, skikkaSpurn] = useState(false);
  const [spurnÁrn, skikkaSpurnÁrn] = useState(false);

  const dagskrá = useRef();

  const sendaÞángað = useNavigate();

  useEffect(() => {
    async function náíGögnVallar() {
      try {
        const svarMiðlara = await miðlarabón.get("/vellir/vollur/" + einkivallar);
        if (svarMiðlara.status === 200) {
          const vallargögn = svarMiðlara.data;
          vallargögn.síndstærð = fáLeingdOgBreiddVallar(vallargögn.leingd, vallargögn.breidd);
          skikkaVelli(vallargögn);
        }
      } catch (villaBónar) {
        skikkaMiðlaravillu(fáMiðlaravillu(villaBónar));
      }
      skikkaSækirVallargögn(null);
    }
    náíGögnVallar();
  }, [einkivallar])


  function fáLeikhætti() {
    const leikhættir = völlur.leikhættir;
    if (!leikhættir) {
      return [];
    }
    const fjöldi = leikhættir.length;
    const listi = [];
    for (let i = 0; i < fjöldi; i++) {
      listi.push(
        <div key={leikhættir[i].leikháttur} className="völlur-leikháttur">
          {leikhættir[i].leikháttur}
        </div>
      )
    }
    return listi;
  }

  useEffect(() => {

    if (sjáDagskráVallar) {
      dagskrá.current.scrollIntoView({behavior: "smooth"});
    }

  }, [sjáDagskráVallar])


  const umVöll = (völlur && völlur.um) ? völlur.um.split("\n") : null;
  const verðVallar = (völlur && völlur.verð) ? völlur.verð.split("\n") : null;

  return (
    <section className="völlur-aðal">
      {sækirVallargögn && <div className="villaoghleður-heilsíða villaoghleður-hleður">{sækirVallargögn}</div>}
      {miðlaravilla && <div className="villaoghleður-heilsíða villaoghleður-villa">{miðlaravilla}</div>}
      {völlur && (
        <>
          <h1 className="völlur-firirsögn">{völlur.nafn}</h1> 
          {völlur.aukanafn && <h3 className="völlur-undirfirirsögn">{völlur.aukanafn} {völlur.stærð_undirvallar}</h3>}
          <div className="völlur-mindogupplísíngar">
            <img className="völlur-mind" src={`${slóðminda}vallamindir/${völlur.aðalmind}`} alt="mynd af velli"/>
            <div className="völlur-allskonarupplísíngar">
              {völlur.aðalvöllur && <p className="völlur-upplísíngar">
                <TakkiAðgerð1 texti="Sjá aðalvöll" aðgerð={() => skikkaSjáAðalvöll(true)}/>
              </p>}
              <p className="völlur-upplísíngar">{`Nágrenni: ${völlur.nágrenni}`}</p>
              <p className="völlur-upplísíngar">{`Undirlag: ${völlur.undirlag}`}</p>
              <p className="völlur-upplísíngar">{völlur.úti ? "Umhverfi: Útivöllur" : "Umhverfi: Innivöllur"}</p>
              {völlur.stærð_vallar && <p className="völlur-upplísíngar">Stærð: {völlur.stærð_vallar} <span onClick={() => skikkaSpurn(true)} className="spurn smellanlegur">?</span></p>}
              <p className="völlur-upplísíngar">Tegund: {völlur.tegund}</p>
              <p className="völlur-upplísíngar">Árnaðarmaður: {völlur.árnaðarmaður ? "Já" : "Nei"} <span onClick={() => skikkaSpurnÁrn(true)} className="spurn smellanlegur">?</span></p>
              <p className="völlur-upplísíngar">Einkavöllur: {(völlur.einkavöllur ? "Já" : "Nei")}</p>
              {völlur.síndstærð && (
                <div className="völlur-leikvángur" style={völlur.síndstærð}>
                  <p className="völlur-leikvángur-stærð völlur-breidd">{völlur.breidd} metrar</p>
                  <p className="völlur-leikvángur-stærð völlur-leingd">{völlur.leingd} metrar</p>
                </div>
              )}
            </div>
            {umVöll && (
              <>
                <p className="völlur-um">Um:</p>
                <LínuEfnisgrein listi={umVöll}/>
              </>
            )}
            {verðVallar && (
              <>
                <p className="völlur-um">Verð:</p>
                <LínuEfnisgrein listi={verðVallar}/>
              </>
            )}
  
            {/* <p className="völlur-um-texti texti">{völlur.um}</p>
            <p className="völlur-um">Verð:</p> 
            <p className="völlur-um-texti texti">{völlur.verð}</p> */}
          </div>
          {völlur.undirvellir && (
            <>
              <h3 className="völlur-leikhættir-firirsögn">Undirvellir vallar</h3>
              <div className="völlur-undirvellir">
                <Listi
                  tegund="vellir"
                  breidd={2}
                  listi={völlur.undirvellir}
                />
              </div>
            </>
          )}
          <h3 className="völlur-leikhættir-firirsögn">Leikhættir vallar</h3>
          <div className="völlur-leikhættir">
            {fáLeikhætti()}
          </div>
          <div className="völlur-hlekkir">
            <a 
              href={`https://ja.is/kort/?q=${völlur.norður},${völlur.austur}&nz=16&type=aerialnl`} 
              target="_blank" 
              className=""
              rel="noreferrer"
            >Sjá staðsetningu vallar á korti</a>
            <a 
              href={`https://www.vedur.is/vedur/spar/sparit/#m1=${völlur.veðurstöð}`} 
              target="_blank" 
              className=""
              rel="noreferrer"
            >Sjá veðurspá fyrir völl</a>
          </div>
          {!árnaðarmaður && (
            <>
              <div className="völlur-búatilleik-takki">
                <TakkiAðgerð2 texti="Búa til leik á velli" aðgerð={() => {
                  const búatilleikUpplísíngar = {
                    völlur: {
                      einki: Number(einkivallar), 
                      nafn: völlur.nafn, 
                      mind1: völlur.aðalmind,
                      aðalmind: völlur.aðalmind  
                    }
                  }
                  
                  localStorage.setItem("buatilleik-upplisingar", JSON.stringify(búatilleikUpplísíngar));
                  if (lokasmeiju) {
                    lokasmeiju(false);
                  } 
                  sendaÞángað("/buatilnijanleik?vollur=satt");
                }}/>
              </div>
              <div className="völlur-sjádagskrá-takki">
                <TakkiAðgerð1 
                  texti={sjáDagskráVallar ? "Fela dagskrá" : "Sjá dagskrá vallar"} 
                  aðgerð={() => {
                    skikkaSjáDagskráVallar(firraGildi => !firraGildi)
                  }}
                />
              </div>
              {sjáDagskráVallar &&
                <>
                  <h4 className="völlur-tími-firirsögn" ref={dagskrá}>Dagskrá vallar</h4>
                  <div className="völlur-umgjörðtíma">
                    <VeljaTíma 
                      einkiVallar={einkivallar} 
                      völlurVallarsíðu={{
                        einki: einkivallar, 
                        nafn: völlur.nafn, 
                        mind1: völlur.aðalmind,
                        aðalmind: völlur.aðalmind   
                      }}
                    />
                  </div>
                </> 
              }
            </>
          )}
        </>
      )}
      {sjáAðalvöll && (
        <Smeija lokaSmeiju={skikkaSjáAðalvöll}>
          <Völlur einkivallar={völlur.aðalvöllur}/>
        </Smeija>
      )}
      <SpurnStærðValla spurn={spurn} skikkaSpurn={skikkaSpurn}/>
      {spurnÁrn && (
        <Smeija lokaSmeiju={skikkaSpurnÁrn}>
          <p className="smeija-aðaltakki spurn-rammi">Árnaðarmaður: Sá sem sér um völl, skráir upptekna tíma o.s.frv.</p>
        </Smeija>
      )}
    </section>
  )
}

export default Völlur;