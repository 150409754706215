import miðlarabón from "../miðlarabón";
import { fáMiðlaravillu } from "./ímsir";


function fáListaBirjun(firri, næsti, vísalisti, erNæsti) {
  if (firri) {
    if (vísalisti.length > 2 && erNæsti) {
      //[..., firri, nú, næsti]
      return vísalisti[vísalisti.length - 3];
    } else if (vísalisti.length > 1) {
      //[..., firri, nú]
      return vísalisti[vísalisti.length - 2];
    }
  }

  if (næsti) {
    //[..., næsti]
    return vísalisti[vísalisti.length - 1];
  }

  return vísalisti[0];
}

function fáNæstaVísi(feinginnListi, mestiFjöldiíLista, veffáng, tími, liðsröðun) {
  const síðastaStak = feinginnListi[mestiFjöldiíLista - 1];
  if (tími) {
    if (veffáng === "leikir/kappleikur/saekja/bonirmedtima?" || liðsröðun) {
    //svo hægt er að raða þó það er fleiri en ein bón frá sama leiknum (með mismunandi liði)
      return [síðastaStak.tími_frá, síðastaStak.einki, síðastaStak.mittliðeinki]
    }
    return [síðastaStak.tími_frá, síðastaStak.einki]; 
  }
  if (veffáng === "leikir/kappleikur/saekja/bonirantima?" || liðsröðun) {
    //svo hægt er að raða þó það er fleiri en ein bón frá sama leiknum (með mismunandi liði)
    return [síðastaStak.einki, síðastaStak.mittliðeinki]
  }
  if (síðastaStak.raðeinki) {
    return síðastaStak.raðeinki;
  }

  return síðastaStak.einki;
}

//kannski útskíra tilgáng kvert inntaks
async function sækjaFlettilista(
  notaLeitarorð, 
  firri, 
  næsti, 
  vísalisti, 
  skikkaVillu, 
  skikkaHleður, 
  athugunLeitarorðs,
  erNæsti,
  veffáng,
  mestiFjöldiíLista,
  skikkaBirjaListaVísi,
  skikkaErNæsti,
  skikkaLista,
  tími,
  liðsröðun,
  firirLokin,
  skikkaSækjandi,
  skikkaGutlara,
) {
  if (skikkaSækjandi) {
    //firir flettíngarlisti margir svo ekki er hægt að skifta um lista þegar verið er að ná í lista
    skikkaSækjandi(true)
  }
  skikkaVillu(null);
  skikkaHleður("Sækir...");
  

  if (athugunLeitarorðs) {
    const villaLeitarorðs = athugunLeitarorðs(notaLeitarorð);
    if (villaLeitarorðs) {
      skikkaVillu(villaLeitarorðs);
      skikkaHleður(null);
      return;
    }
  }

  const birjaSóttanLista = fáListaBirjun(firri, næsti, vísalisti, erNæsti);

  try {

    const sækjaLista = await miðlarabón.get(`${veffáng}leitarord=${notaLeitarorð}&birjanaesta=${birjaSóttanLista}`);


    const feinginnListi = sækjaLista.data;
    let nírVísalisti = vísalisti;
    let gildiNæsta = true;

    const fjöldiíLista = feinginnListi.length;
    const fullurListi = fjöldiíLista > mestiFjöldiíLista;
    gildiNæsta = fullurListi;
    
    if (firri) {
      if (fullurListi) {
        //því ef bón kvervur gæti verið að hann væri ekki fullur
        feinginnListi.pop();
      } else {
        nírVísalisti.pop();
      }
      if (erNæsti) {
        nírVísalisti.pop();
      }
      
    } else {

      if (fullurListi) {
        const næstiVísir = fáNæstaVísi(feinginnListi, mestiFjöldiíLista, veffáng, tími, liðsröðun);
        //ef ekki næsti (líka firri) þá er nítt leitarorð
        nírVísalisti = næsti ? [...vísalisti, næstiVísir] : [vísalisti[0], næstiVísir];
        feinginnListi.pop();
      } else if (!næsti) {
        //þ.e.a.s. ef er ní leit
        nírVísalisti = [vísalisti[0]];
      }
      
    }
    
    if (firirLokin) {
      firirLokin.current = {
        listi: feinginnListi, 
        erNæsti: gildiNæsta, 
        birjaListaVísir: nírVísalisti,
        villa: null
      }; 
    }
    skikkaBirjaListaVísi(nírVísalisti);
    skikkaErNæsti(gildiNæsta);
    skikkaLista(feinginnListi);
  } catch (villaAðgerð) {
    const villuskilaboð = fáMiðlaravillu(villaAðgerð);
    if (villuskilaboð === "Ekki skráður inn!" && skikkaGutlara) {
      //svo hægt er að skrá út þegar hann er ekki skráður inn með vafraköku
      skikkaGutlara({gutlaranafn: null});
    }
    if (firirLokin) {
      firirLokin.current = {
        listi: [], 
        erNæsti: false, 
        birjaListaVísir: [],
        villa: villuskilaboð
      };
    }
    skikkaVillu(villuskilaboð);
    skikkaLista([]);

  }
  skikkaHleður(null);
  if (skikkaSækjandi) {
    skikkaSækjandi(false);
  }
}

export default sækjaFlettilista;