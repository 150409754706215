import fáTímasetníngarLesmál from "../../../../gjörníngar/fáTímasetnínguLesmál";
import "../../../../almenncss/grunncss.css";
import { fákommu } from "../../../../gjörníngar/ímsir";



const SínaGreiðslu = ({greiðsla}) => {


  return (
    <div className="texti">
      <p>Upphæð: {greiðsla.upphæð ? fákommu(greiðsla.upphæð) : 0}</p>
      <p>Aðferð: {greiðsla.textiAðferð}</p>
      <p>Greiðslunúmer: {greiðsla.greiðslunúmer}</p>
      <p>Tími: {greiðsla.tími && fáTímasetníngarLesmál(greiðsla.tími)}</p>
    </div>

  )
}


export default SínaGreiðslu;