
import "../leikurtímaskrá.css";
import "../../../../../../../almenncss/grunncss.css";
import { useState } from "react";
import { fáMiðlaravillu, jáEðaNei } from "../../../../../../../gjörníngar/ímsir";
import fáTímasetníngarLesmál from "../../../../../../../gjörníngar/fáTímasetnínguLesmál";
import VilluHleðurTakki2 from "../../../../../villaoghleður/villuhleðurtakki/VilluHleðurTakki2";
import miðlarabón from "../../../../../../../miðlarabón";
import Smeija from "../../../../../smeija/Smeija";

const TímaskráGreiðsla = ({
  gögnTímaskráar, 
  greiðslaStaðfest,
  einki,
  fasturTími
}) => {
  const [svar, skikkaSvari] = useState(null);
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);

  const [spurnGreiðsla, skikkaSpurnGreiðsla] = useState(false);

  async function breitaGreiðslustöðu() {
    skikkaHleður(greiðslaStaðfest.current ? "Fjarlægir staðfestingu..." : "Staðfestir greiðslu...");
    skikkaVillu(null);

    const slóð = fasturTími ? `fastur/stadfestagreidslu/${einki}` : `timaskra/stadfestagreidslu/${einki}`;

    try {
      const staðfestaGreiðslu = await miðlarabón.put(slóð);
      greiðslaStaðfest.current = !greiðslaStaðfest.current;
      skikkaSvari(staðfestaGreiðslu.data);
    
    } catch (villaBónar) {
      skikkaVillu(fáMiðlaravillu(villaBónar));
    }
    skikkaHleður(null);
  }
  
  return (
    <>
      <p className="smeija-aðaltakki">Greiðsla: <span onClick={() => skikkaSpurnGreiðsla(true)} className="spurn smellanlegur">?</span></p>
      <div className="texti">
        <p>Upphæð: {gögnTímaskráar.upphæð}</p>
        <p>Aðferð: {gögnTímaskráar.aðferð}</p>
        <p>Greiðslunúmer: {gögnTímaskráar.greiðslunúmer}</p>
        <p>Tími: {fáTímasetníngarLesmál(gögnTímaskráar.tími)}</p>
        <p>Skráð staðfest: {jáEðaNei(greiðslaStaðfest.current)}</p>
        <div className="leikurtímaskrá-greiðslatakki">
          <VilluHleðurTakki2
            textiAðgerðar={greiðslaStaðfest.current ? "Fjarlægja staðfestingu" : "Staðfesta greiðslu"}
            aðgerð={breitaGreiðslustöðu}
            svarMiðlara={svar}
            hleður={hleður}
            villa={villa}
          />
        </div>
      </div>
      {spurnGreiðsla && (
        <Smeija lokaSmeiju={skikkaSpurnGreiðsla}>
          <p className="smeija-aðaltakki spurn-rammi">Hér er greiðsla sem {fasturTími ? "ætlunarstefnir" : "leikstefnir"} hefur skráð. Þú getur notað hana til ákvarða hvort á að skrá {fasturTími ? "fasta tímann" : "leikinn"} sem upptekinn. </p>
          <p className="smeija-aðaltakki spurn-rammi">Ef þessi greiðsla passar við greiðslu sem þú hefur fengið (með millifærslu eða annað), þá er hentugt að staðfesta greiðsluna. Þannig getur þú á auðveldari hátt haldið utan um hvaða greiðslur eru greiddar.</p>
        </Smeija>
      )}
    </>
  )
}


export default TímaskráGreiðsla;