import "../../../../almenncss/grunncss.css";
import "../../../../almenncss/villaoghleður.css";
import "../greiðsla.css";
import "../../../../síðurnar/búatilníjanleik/hægribúatilníjanleik/veljaaðraeiginleikaleiks/veljaaðraeiginleikaleiks.css";
import { useRef, useState } from "react";
import ÚtfillíngarMöguleikar from "../../útfillíngar/útfillíngarmöguleikar/ÚtfillíngarMöguleikar";
import ÚtfillíngarTexti from "../../útfillíngar/útfillíngartexti/ÚtfillíngarTexti";
import LínuEfnisgrein from "../../línuefnisgrein/LínuEfnisgrein";
import Smeija from "../../smeija/Smeija";
import TakkiAðgerð2 from "../../takkar/takkiAðgerð2/TakkiAðgerð2";
import fáTímasetníngarLesmál from "../../../../gjörníngar/fáTímasetnínguLesmál";
import { fákommu } from "../../../../gjörníngar/ímsir";
import { fáDagLesmál, fáFráTilLesmál } from "../../../../gjörníngar/fáTímaLesmál";


function fáAðferð(aðferð) {
  if (aðferð === "Reiðufé") return 1;
  if (aðferð === "Millifærsla") return 2;
  if (aðferð === "Aur") return 3;
  if (aðferð === "Bitamynt (Bitcoin)") return 4;
  if (aðferð === "Friðmynt (Monero)") return 5;
  if (aðferð === "Annað") return 6;
  return 6;
}

function fáTölu(texti) {
  const tala = Number(texti);
  if (!isNaN(tala)) {
    return {tala, erTala: true};
  }
  const nírTexti = texti.replace(",", ".");
  const níTala = Number(nírTexti);
  if (!isNaN(níTala)) {
    return {tala: níTala, erTala: true};
  }
  return {erTala: false};
   
}


const SkráGreiðslu = ({
  verð, 
  breitaGreiðslu, 
  valinGreiðsla, 
  lokaSmeiju,
  tímasetníng,
  firirætlun
}) => {
  const [aðferð, skikkaAðferð] = useState(valinGreiðsla ? valinGreiðsla.textiAðferð : "Reiðufé");
  const [upphæð, skikkaUpphæð] = useState(valinGreiðsla ? fákommu(valinGreiðsla.upphæð) : 0);
  const [villa, skikkaVillu] = useState(null);

  const [spurn, skikkaSpurn] = useState(false);
  const [spurnNúmer, skikkaSpurnNúmer] = useState(false);
  const [spurnTími, skikkaSpurnTími] = useState(false);

  const greiðslunúmer = useRef(valinGreiðsla ? valinGreiðsla.greiðslunúmer : "");
  const [tími, skikkaTíma] = useState(valinGreiðsla ? valinGreiðsla.tímiTexti : "");

  function breitaGreiðslunúmeri(breitt) {
    greiðslunúmer.current = breitt;
  }


  function veljaGreiðslu() {

    const valinAðferð = fáAðferð(aðferð);

    if (greiðslunúmer.length > 1000) {
      skikkaVillu("Þetta greiðslunúmer er alltof langt");
      return;
    }

    const valinnTími = new Date(tími);

    if (valinnTími.toString() === "Invalid Date") {
      skikkaVillu("Vitlaus uppsetning á tíma");
      return;
    }

    const {tala, erTala} = fáTölu(upphæð);
    if (!erTala) {
      skikkaVillu("Vitlaus uppsetning á upphæð");
      return;
    }
    
    lokaSmeiju(false);
    breitaGreiðslu({
      aðferð: valinAðferð,
      textiAðferð: aðferð,
      upphæð: tala,
      greiðslunúmer: greiðslunúmer.current,
      tími: valinnTími,
      tímiTexti: tími
    });
    
  }

  function fáTíma() {
    if (tími.length < 4) {
      return null;
    }

    const valinnTími = new Date(tími);

    if (valinnTími.toString() === "Invalid Date") {
      return <p className="smeija-lítilltakki skrágreiðslu-tími">Valinn tími: <span className="villaoghleður-villa">Vitlaus uppsetning</span></p>;
    } else {
      return <p className="smeija-lítilltakki skrágreiðslu-tími">Valinn tími: <span className="villaoghleður-tókst">{fáTímasetníngarLesmál(valinnTími)}</span></p>;
    }

  }

  return (
    <section className="veljaeiginleiks-umgjörð umgjörð-lóðrétt-ómiðjað">
      <p className="smeija-aðaltakki">Skrá greiðslu <span onClick={() => skikkaSpurn(true)} className="spurn smellanlegur">?</span></p>
      <p className="smeija-takki skrágreiðslu-verð">Verð vallar:</p>
      <LínuEfnisgrein listi={verð}/>
      {tímasetníng && (
        <>
          <p className="smeija-takki">{tímasetníng.dagur}</p>
          <p className="smeija-takki">{tímasetníng.tími}</p>
        </>
      )}
      {(firirætlun && firirætlun.tímaskrár) && (
        firirætlun.tímaskrár.map(tímaskrá => (
          <>
            <p className="smeija-takki">{fáDagLesmál(new Date(tímaskrá.tími_frá))}</p>
            <p className="smeija-takki">{fáFráTilLesmál(new Date(tímaskrá.tími_frá), new Date(tímaskrá.tími_til))}</p>
          </>
        ))
      )}
      <div className="veljaeiginleiks-kassi skrágreiðslu-útfillíng">
        <ÚtfillíngarTexti
          texti="Upphæð" 
          gildi={upphæð} 
          breitaGildi={skikkaUpphæð}
        />
      </div>
      <div className="veljaeiginleiks-kassi">
        <ÚtfillíngarMöguleikar 
          texti="Greiðsluaðferð" 
          gildi={aðferð} 
          breitaGildi={skikkaAðferð} 
          möguleikar={["Reiðufé", "Millifærsla", "Aur", "Bitamynt (Bitcoin)", "Friðmynt (Monero)", "Annað"]}
        />
      </div>
      <div className="veljaeiginleiks-kassi">
        <ÚtfillíngarTexti 
          texti="Greiðslunúmer" 
          gildi={greiðslunúmer.current} 
          breitaGildi={breitaGreiðslunúmeri}
          birtaSmeiju={() => skikkaSpurnNúmer(true)}
        />
      </div>
      <p className="smeija-lítilltakki">Tímann skal skrá á þessu formi: ár-mánuður-dagur klst:mín</p>
      <p className="smeija-lítilltakki">Sem dæmi: 2025-07-05 08:06</p>
      <div className="veljaeiginleiks-kassi">
        <ÚtfillíngarTexti 
          texti="Tími" 
          gildi={tími} 
          breitaGildi={skikkaTíma}
          birtaSmeiju={() => skikkaSpurnTími(true)}
        />
      </div>
      {fáTíma()}
      {villa && <p className="veljaeiginleiks-firirsögn-smeiju villaoghleður-villa">{villa}</p>}
      <div className="veljaeiginleiks-staðfestaval-kassi">
        <TakkiAðgerð2 texti="Staðfesta val" aðgerð={veljaGreiðslu}/>
      </div>
      {spurn && (
        <Smeija lokaSmeiju={skikkaSpurn}>
          <p className="smeija-aðaltakki spurn-rammi">Fyrst greiðiru fyrir völlinn með gefinni greiðsluaðferð (ekki á þessari síðu!), þá geturu skráð upplýsingar um greiðsluna hér. Skráningin auðveldar árnaðarmanni vallar að tengja saman greiðslur við tímaskráningar.</p>
        </Smeija>
      )}
      {spurnNúmer && (
        <Smeija lokaSmeiju={skikkaSpurnNúmer}>
          <p className="smeija-aðaltakki spurn-rammi">Með hverri rafrænni færslu fylgir greiðslunúmer/færlsunúmer, eða annað slíkt, það skal skrá hér. En fylgstu líka með hvort árnaðarmaður nefnir í verði vallar hvort það á að skrá eitthvað annað í þennan reit.</p>
        </Smeija>
      )}
      {spurnTími && (
        <Smeija lokaSmeiju={skikkaSpurnTími}>
          <p className="smeija-aðaltakki spurn-rammi">Tíminn sem þú skráir hér er tími greiðslunnar. Ef greiðslan er rafræn ætti tími færslu alltaf að fylgja í upplýsingum um hana.</p>
        </Smeija>
      )}
    </section>


  )
}


export default SkráGreiðslu;