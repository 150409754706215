import "../veljalið/veljalið.css";
import FlettíngarListi from "../flettíngartakkar/FlettíngarListi";
import "../../../síðurnar/búatilníjanleik/hægribúatilníjanleik/veljaaðraeiginleikaleiks/veljaaðraeiginleikaleiks.css";
import VilluHleðurTakki from "../villaoghleður/villuhleðurtakki/VilluHleðurTakki";
import { useState } from "react";
import miðlarabón from "../../../miðlarabón";
import { fáMiðlaravillu } from "../../../gjörníngar/ímsir";
import TakkiAðgerð1 from "../takkar/takkiAðgerð1/TakkiAðgerð1";
import slóðminda from "../../../slóðminda";
import Smeija from "../smeija/Smeija";
import Liðasmeija from "../liðasmeija/Liðasmeija";

const VeljaLiðFastur = ({
  einkiFastsTíma, 
  skikkaFöstumTíma, 
  lokaSmeiju, 
  skikkaBreittíFirirætlun,
  skikkaBreittriUpptekni,
  skikkaSjáTímaskrár
}) => {

  const [valiðLið, skikkaVölduLiði] = useState(null);

  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);

  const [liðasmeija, skikkaLiðasmeiju] = useState(false);

  //búa til leik eða firirætlun úr tímaskrá/föstum tíma
  async function búaTil() {
    skikkaVillu(null);
    skikkaHleður("Býr til firirætlun...");

    try {
      await miðlarabón.post("fastur/buatilfiriraetlun", {einkiFastsTíma, einkiLiðs: valiðLið.einki});
      skikkaFöstumTíma(firriFastur => {
        const uppfærðFastur = {...firriFastur};
        uppfærðFastur.upptekni = 2;
        uppfærðFastur.er_firirætlun = true;
        return uppfærðFastur;
      });
      skikkaBreittriUpptekni(2);
      //svo það er ekki ennþá sínt tímaskrár firir breitíngu
      skikkaSjáTímaskrár(false);
      lokaSmeiju(false);
      skikkaBreittíFirirætlun(true);
    } catch (villaBónar) {
      skikkaVillu(fáMiðlaravillu(villaBónar))
      skikkaHleður(null);
    }
  }

  return (
    <>
      <h3 className="veljalið-firirsögn-smeiju">Veldu lið fyrirætlunar</h3>
      {valiðLið ? (
        <>
          <div className="umgjörð-lóðrétt-ómiðjað">
            <p className="veljaeiginleiks-varavöllur-texti">Lið fyrirætlunar</p>
            <img 
              className="veljaeiginleiks-lið-skjaldamerki smellanlegur" 
              src={`${slóðminda}lidamindir/${valiðLið.skjaldamerki ? valiðLið.skjaldamerki : "170809081376912242.png"}`} alt="Skjaldarmerki liðs"
              onClick={() => skikkaLiðasmeiju(true)}
            />
            <p className="veljaeiginleiks-varavöllur-texti">{valiðLið.nafn}</p>
            <div className="veljaeiginleiks-varavöllur-takkar">
              <div className="veljaeiginleiks-varavöllur-takkakassi">
                <TakkiAðgerð1 
                  texti="Breyta völdu liði" 
                  aðgerð={() => skikkaVölduLiði(null)} 
                />
              </div>
            </div>
          </div>
          <div className="smeija-takki">
            <VilluHleðurTakki
              hleður={hleður}
              villa={villa}
              aðgerð={búaTil}
              textiAðgerðar="Búa til fyrirætlun úr ótækum föstum tíma"
            />
          </div>
        </>
      ) : (
        <div className="veljalið-listi-ivir-lið">
          <FlettíngarListi
            veffáng="arnadarmadur/faolllid?"
            tegundLista="liðgögn"
            breiddLista={3}
            textiAðgerðarLista="Velja þetta lið"
            aðgerðLista={(lið) => skikkaVölduLiði(lið)}
            mestiFjöldiíLista={24}
            leit={true}
            stærð={1}
          />
        </div>
      )}
      {liðasmeija && (
        <Smeija lokaSmeiju={skikkaLiðasmeiju}>
          <Liðasmeija lið={{lið: valiðLið}} erÁrnaðarmaður={true}/>
        </Smeija>
      )}
    </>
  )
}

export default VeljaLiðFastur;