import React, { useState } from "react"

import "../leppurtímaskrá/leppurtímaskrá.css"
import "../leppuralmennleikur/leppuralmennleikur.css";
import "../../../../almenncss/villaoghleður.css";
import { fáDagLesmál, fáDagLesmálÞágu, vikudagarÞáguFleir, fáKlstMínLesmál } from "../../../../gjörníngar/fáTímaLesmál";
import Smeija from "../../smeija/Smeija";
import { fáUpptekni } from "../leppurtímaskrá/LeppurTímaskrá.js";
import ÓtækurFasturSmeija from "../../smeijur/ótækurfastursmeija/ÓtækurFasturSmeija.js"


const LeppurFasturTími = ({ valinnFasturTími }) => {
  const [smeija, skikkaSmeiju] = useState(false);
  const [fasturTími, skikkaFöstumTíma] = useState(valinnFasturTími);

  const upptekni = fáUpptekni(fasturTími.upptekni);
  
  return (
    <>
      <div className="leppurleikur-leikur" onClick={() => skikkaSmeiju(true)}>
        {(fasturTími.tegund_tímabils == 1) && (
          <>
            <p className="leppurleikur-tími-leiks">Vikulega</p>
            <p className="leppurleikur-tími-leiks">{vikudagarÞáguFleir[fasturTími.dagur - 1]}</p>
          </>
        )}
        {(fasturTími.tegund_tímabils == 2) && (
          <>
            <p className="leppurleikur-tími-leiks">Mánaðarlega</p>
            <p className="leppurleikur-tími-leiks">{fasturTími.dagur}. hvers mánaðar</p>
          </>
        )}
        <p className="leppurleikur-tími-leiks">{fáKlstMínLesmál(fasturTími.klst_frá, fasturTími.mín_frá, fasturTími.klst_til, fasturTími.mín_til)}</p>
        <p className="leppurleikur-dagsetníng-leiks">{fáDagLesmál(new Date(fasturTími.tímabil_frá))}</p>
        <p className="leppurleikur-tími-leiks">Til og með</p>
        <p className="leppurleikur-dagsetníng-leiks">{fáDagLesmálÞágu(new Date(fasturTími.tímabil_til))}</p>
        {upptekni && <p className="leppurleikur-tími-leiks">{upptekni}</p>}
        {fasturTími.aðaltími && <p className="leppurleikur-tími-leiks">Aukatími</p>}
        {fasturTími.greiðsla && <p className="leppurleikur-tími-leiks">Skráð greiðsla</p>}
        {fasturTími.staðfest_greiðsla && <p className="leppurleikur-tími-leiks">Greiðsla staðfest</p>}
        {fasturTími.ní_skilaboð && <p className="leppurleikur-tími-leiks">NÝ SKILABOÐ</p>}
        {fasturTími.fargaður && <p className="leppurleikur-tími-leiks">FARGAÐ</p>}
      </div>
      {smeija && (
        <Smeija lokaSmeiju={skikkaSmeiju}>
          <ÓtækurFasturSmeija fasturTími={fasturTími} skikkaFöstumTíma={skikkaFöstumTíma}/>
        </Smeija>
      )}
    </>
  )
}

export default LeppurFasturTími;