import React, { useState } from "react"

import "./leppurtímaskrá.css"
import "../leppuralmennleikur/leppuralmennleikur.css";
import "../../../../almenncss/villaoghleður.css";
import { fáFráTilLesmál, fáDagLesmál, fáDagLesmálÞágu } from "../../../../gjörníngar/fáTímaLesmál";
import Smeija from "../../smeija/Smeija";
import LeikurTímaskrá from "./smeijur/leikurtímaskrá/LeikurTímaskrá.js";
import ÓtækurTímaskrá from "./smeijur/ótækurtímaskrá/ÓtækurTímaskrá.js";
import { sólarhríngur } from "../../../../almenngögn.js";

export function fáTegund(tala) {
  //samsvarar gildi í gagnagrunni
  if (tala === 1) return "Gutlaraleikur";
  if (tala === 2) return "Kappleikur";
  return null;
}

export function fáUpptekni(tala) {
  //samsvarar gildi í gagnagrunni
  if (tala === 1) return "Uppteknarbón";
  if (tala === 2) return "Upptekinn";
  if (tala === 3) return "Skipaður";
  if (tala === 4) return "Ótækur";
  return null;
}

function fáSmeiju(tímaskrá, skikkaTímaskrá) {
  if (tímaskrá.upptekni === 4 || (tímaskrá.áætlun && !tímaskrá.tegund_leiks)) {
    //ótækur eða firirætlunartímaskrá án leiks
    return <ÓtækurTímaskrá tímaskrá={tímaskrá} skikkaTímaskrá={skikkaTímaskrá}/>
  }
  return <LeikurTímaskrá tímaskrá={tímaskrá} skikkaTímaskrá={skikkaTímaskrá}/>
}

const LeppurTímaskrá = ({ valinTímaskrá }) => {
  const [smeijaTímaskrá, skikkaSmeijuTímaskrá] = useState(false);
  const [tímaskrá, skikkaTímaskrá] = useState(valinTímaskrá);


  const tímiFrá = new Date(tímaskrá.tími_frá);
  const tímiTil = new Date(tímaskrá.tími_til);

  const leingd = tímiTil.getTime() - tímiFrá.getTime()
  const erTímabil = leingd > sólarhríngur;
  const erSólarhríngur = leingd === sólarhríngur;

  

  const tegund = fáTegund(tímaskrá.tegund_leiks);
  const upptekni = fáUpptekni(tímaskrá.upptekni);


  return (
    <>
      <div className="leppurleikur-leikur" onClick={() => skikkaSmeijuTímaskrá(true)}>
        {erTímabil && (
          <>
            <p className="leppurleikur-dagsetníng-leiks">{fáDagLesmál(tímiFrá)}</p>
            <p className="leppurleikur-tími-leiks">Til og með</p>
            <p className="leppurleikur-dagsetníng-leiks">{fáDagLesmálÞágu(new Date(tímiTil.getTime() - 1))}</p>
          </>
        )}
        {erSólarhríngur && (
          <>
            <p className="leppurleikur-dagsetníng-leiks">{fáDagLesmál(tímiFrá)}</p>
            <p className="leppurleikur-tími-leiks">Allan sólarhringinn</p>
          </>
        )}
        {(!erTímabil && !erSólarhríngur) && (
          <>
            <p className="leppurleikur-dagsetníng-leiks">{fáDagLesmál(tímiFrá)}</p>
            <p className="leppurleikur-tími-leiks">{fáFráTilLesmál(tímiFrá, tímiTil)}</p>
          </>
        )}
        {tegund && <p className="leppurleikur-tími-leiks">{tegund}</p>}
        {upptekni && <p className="leppurleikur-tími-leiks">{upptekni}</p>}
        {tímaskrá.aðaltími && <p className="leppurleikur-tími-leiks">Aukatímaskrá</p>}
        {tímaskrá.áætlun && <p className="leppurleikur-tími-leiks">Hluti af föstum tíma</p>}
        {tímaskrá.greiðsla && <p className="leppurleikur-tími-leiks">Skráð greiðsla</p>}
        {tímaskrá.staðfest_greiðsla && <p className="leppurleikur-tími-leiks">Greiðsla staðfest</p>}
        {tímaskrá.ní_skilaboð && <p className="leppurleikur-tími-leiks">NÝ SKILABOÐ</p>}
        {tímaskrá.fargað && <p className="leppurleikur-tími-leiks">FARGAÐ</p>}
      </div>
      {smeijaTímaskrá && (
        <Smeija lokaSmeiju={skikkaSmeijuTímaskrá}>
          {fáSmeiju(tímaskrá, skikkaTímaskrá)}
        </Smeija>
      )}
    </>
  )
}

export default LeppurTímaskrá;